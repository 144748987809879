
<template>
  <div class="solution-label">
    <div class="new-label">
      <p class="name">{{ labelText }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 标签名
    labelText: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.solution-label {
  .new-label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 6px 8px;
    background: @theme;
    border-radius: 0 0 12px 0;
    .name {
      font-size: 14px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 19px;
    }
  }
  @media screen and (max-width: 768px) {
    .new-label {
      .name {
        font-size: 12px;
      }
    }
  }
}
</style>
