<template>
  <div v-if="Object.keys(homeData).length" class="home-wrap">
    <!-- banner -->
    <div class="banner-box">
      <swiper ref="tabSwiper" class="banner-swiper" :options="bannerSwiperOption">
        <swiper-slide v-for="(item, index) in homeData.bannerInfo" :key="index">
          <div class="banner-img show-banner-pc" :style="`background: url('${item.img_image}') center center / cover no-repeat`" @click="handleBannerLink(item)"></div>
          <div class="banner-img show-banner-mobile" :style="`background: url('${item.img_mobile_image}') center center / cover no-repeat`" @click="handleBannerLink(item)"></div>
        </swiper-slide>
      </swiper>
      <div class="banner-pagination"></div>
    </div>
    <div class="partner-alliance alliance">
      <div class="container">
        <div class="home-title ">
          <div class="title">{{ homeData.dahua_partner_allian }}</div>
        </div>
        <div class="below-desc" v-html="homeData.banner_below_description">
          {{ homeData.banner_below_description }}
        </div>
        <ul class="alliance-list">
          <li
            v-for="(item, index) in homeData.partner"
            :key="index"
            class="alliance-item"
            @click="handleAllianceLink(item)"
          >
            <div class="alliance-item-img" :style="`background: url(${item.home_image}) center center / cover no-repeat`"></div>
            <div class="alliance-item-text">
              <div class="alliance-title">
                <span class="title">{{ item.nav_name }}</span>
                <span class="iconfont icon-a-jiantouwhite1x"></span>
              </div>
              <p class="alliance-desc">{{ item.nav_description }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Partner Solutions -->
    <div class="partner-solution">
      <div class="container">
        <div class="home-title">
          <div class="title">{{ homeData.partner_solutions }}</div>
          <div class="more">
            <router-link to="/solutions_services">{{ homeData.explore_more }}<i class="iconfont icon-a-jiantouwhite1x"></i></router-link>
          </div>
        </div>
        <!-- pc端 -->
        <ul class="solution-list solution-list-pc">
          <li v-for="(item, index) in homeData.solution" :key="index" class="solution-item" @click="handleSolutionDetail(item)">
            <div class="img-box">
              <img class="img" :src="item.cover_image" alt="" />
              <solution-label v-if="item.is_new === '1'" label-text="NEW" />
            </div>
            <div class="text-box">
              <h5 class="solution-name">{{ item.solution_name }}</h5>
              <public-label
                v-for="(itemChild, index) in item.label_arr"
                :key="index + '1'"
                class="solution-label"
                :title="itemChild.name"
              />
              <!-- 自定义的标签 -->
              <public-label
                v-for="(itemChild, index) in item.custom_label_arr"
                :key="index + '2'"
                class="solution-label"
                :title="itemChild"
              />
            </div>
          </li>
        </ul>
        <!-- 移动端 -->
        <swiper ref="tabSwiper" class="solution-list solution-list-mobile" :options="solutionSwiperOption">
          <swiper-slide v-for="(item, index) in homeData.solution" :key="index" class="solution-item" @click="handleSolutionDetail(item)">
            <div class="img-box">
              <img class="img" :src="item.cover_image" alt="" />
              <solution-label v-if="item.is_new === '1'" label-text="NEW" />
            </div>
            <div class="text-box">
              <h5 class="solution-name">{{ item.solution_name }}</h5>
              <public-label
                v-for="(itemChild, index) in item.label_arr"
                :key="index + '3'"
                class="solution-label"
                :title="itemChild.name"
              />
              <!-- 自定义的标签 -->
              <public-label
                v-for="(itemChild, index) in item.custom_label_arr"
                :key="index + '4'"
                class="solution-label"
                :title="itemChild"
              />
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <!-- Dahua Partner Alliance -->
    <div class="partner-alliance">
      <div class="container">
        <!-- Join Now -->
        <div class="join-now">
          <div class="text-box">
            <h5 class="join-now-title">{{ homeData.want_be_partner }}</h5>
            <p class="join-now-desc">{{ homeData.want_partner_description }}</p>
          </div>
          <div class="btn-box">
            <el-button type="primary" class="btn" @click="handleIoinUsLink()">{{ homeData.join_us_now }}</el-button>
          </div>
        </div>
        <!-- partners -->
        <ul class="partners-list clearfix">
          <li
            v-for="(item, index) in homeData.bottom"
            :key="index"
            class="partners-item"
          >
            <a :href="item.link" target="_blank" rel="nofollow noopener noreferrer" class="link">
              <div class="partners-item-img" :style="`background: url(${item.image}) center center / cover no-repeat`"></div>
              <p class="partners-title">{{ item.title }}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import PublicLabel from '@/components/public/PublicLabel.vue'
import SolutionLabel from '@/components/solutions/SolutionLabel.vue'
import { getHomeApi } from '@/api/home.js'
export default {
  name: 'Home',
  components: {
    swiper,
    swiperSlide,
    PublicLabel,
    SolutionLabel
  },
  data() {
    return {
      bannerSwiperOption: { // banner轮播配置
        autoplay: true,
        // loop: true,
        slidesPerView: 'auto', // 每屏显示个数
        allowTouchMove: true, // 是否可拖动
        pagination: {
          el: '.banner-pagination',
          clickable: true // 是否可以点击
        }
      },
      solutionSwiperOption: { // solution轮播配置
        slidesPerView: '1.5', // 每屏显示个数
        allowTouchMove: true // 是否可拖动
      },
      homeData: {} // 首页数据
    }
  },
  mounted() {
    this.getHomeData()
  },
  methods: {
    // 获取首页接口数据
    getHomeData() {
      getHomeApi().then((res) => {
        this.homeData = res.data
      })
    },
    // banner跳转
    handleBannerLink(item) {
      if (item.link_type === '1') {
        this.$router.push(`${item.link_name}`)
      } else {
        window.open(item.link_url)
      }
    },
    // Partner Solutions跳转
    handleSolutionDetail(item) {
      this.$router.push(`/solutions_services/Introduction/${item.solution_id}`)
    },
    // Dahua Partner Allian跳转
    handleAllianceLink(item) {
      if (item.nav_linktype === '1') {
        this.$router.push(`${item.nav_link_name}`)
      } else {
        window.open(item.nav_linkurl)
      }
    },
    // Join us now!跳转
    handleIoinUsLink() {
      this.$router.push(`/register`)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common';
.home-wrap {
  background: @background;
  .banner-box {
    width: 100%;
    height: 520px;
    position: relative;
    .banner-swiper {
      cursor: pointer;
      .banner-img {
        height: 520px;
      }
      .show-banner-mobile {
        display: none;
      }
    }
    .banner-pagination {
      position: absolute;
      bottom: 16px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
      /deep/ .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        cursor: pointer;
        margin-left: 10px;
      }
      /deep/ .swiper-pagination-bullet-active {
        background: @theme;
      }
    }
  }
  .partner-solution {
    padding-bottom: 48px;
    .home-title {
      padding: 24px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #232931;
        line-height: 56px;
      }
      .more {
        font-size: 16px;
        color: #606266;
        line-height: 24px;
        .transition();
        &:hover {
          &>a {
            color: @theme;
          }
        }
        .iconfont {
          margin-left: 10px;
        }
      }
    }
    .solution-list {
      display: flex;
      .solution-item {
        width: calc(33.333333% - 10px);
        margin-right: 16px;
        background: #fff;
        .transition();
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:hover {
          cursor: pointer;
          .shadow();
        }
        .img-box {
          height: 255px;
          position: relative;
          .img {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .text-box {
          padding: 22px 20px;
          border-top: 1px solid #E4E7ED;
          .solution-name {
            font-size: 18px;
            font-weight: 600;
            color: #303133;
            line-height: 24px;
            margin-bottom: 22px;
          }
          .solution-label {
            margin: 0 10px 10px 0;
          }
        }
      }
    }
    .solution-list-mobile {
      display: none;
    }
  }
  .partner-alliance {
    background: #fff;
    padding-bottom: 72px;
    .home-title {
      font-size: 32px;
      font-weight: 600;
      color: #232931;
      line-height: 48px;
      padding: 24px 0;
    }
    .alliance-list {
      display: flex;
      .alliance-item {
        width: calc(33.333333% - 10px);
        min-height: 432px;
        margin-right: 16px;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:hover {
          .alliance-item-img {
            .scale();
          }
        }
        .alliance-item-img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          .transition();
        }
        .alliance-item-text {
          padding: 48px 24px;
          position: relative;
          .alliance-title {
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
              font-size: 24px;
              font-weight: 600;
              line-height: 32px;
              max-width: 312px;
              word-break: break-word;
							// white-space: nowrap;
							// text-overflow: ellipsis;
							// overflow: hidden;
            }
            .iconfont {
              padding-right: 40px;
            }
          }
          .alliance-desc {
            color: #fff;
            font-size: 16px;
            line-height: 21px;
            margin-top: 24px;
          }
        }
      }
    }
    .join-now {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 0 48px 0;
      .text-box {
        width: 55%;
        .join-now-title {
          font-size: 32px;
          color: #303133;
          line-height: 48px;
        }
        .join-now-desc {
          font-size: 16px;
          color: #606266;
          line-height: 21px;
          margin-top: 24px;
        }
      }
      .btn-box {
        margin-right: 10%;
        .el-button {
          font-size: 16px;
          font-weight: bold;
          line-height: 21px;
        }
      }
    }
    .partners-list {
      .partners-item {
        width: calc(50% - 8px);
        float: left;
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        .link {
          color: #fff;
        }
        &:hover {
          .partners-item-img {
            .scale();
          }
        }
        &:first-child {
          .partners-item-img {
            height: 632px;
          }
          .partners-title {
            padding: 40px 52px;
          }
        }
        &:last-child {
          margin-top: 16px;
        }
        & + .partners-item {
          margin-left: 16px;
        }
        .partners-item-img {
          height: 308px;
          .transition();
        }
        .partners-title {
          position: absolute;
          top: 0;
          left: 0;
          padding: 42px;
        }
      }
    }
  }
  .alliance {
    padding: 36px 0 48px 0;
    background-color: #fff;
    .below-desc {
      margin: 16px 0;
      font-size: 16px;
      color: #606266;
      line-height: 21px;
    }
    .home-title {
      padding: 0;
    }
  }
  @media screen and (max-width: 1366px) {
    .banner-box {
      height: 400px;
      .banner-swiper {
        .banner-img {
          height: 400px;
        }
      }
    }
    .partner-alliance {
      .home-title {
        font-size: 32px;
      }
      .join-now {
        .text-box {
          .join-now-title {
            font-size: 32px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .partner-solution {
      .solution-list {
        .solution-item {
          .img-box {
            height: 180px;
          }
          .text-box {
            .solution-name {
              font-size: 16px;
            }
          }
        }
      }
    }
    .partner-alliance {
      .alliance-list {
        .alliance-item {
          .alliance-title {
            .title {
              font-size: 20px;
              line-height: 30px;
            }
            .iconfont {
              padding-right: 0;
            }
          }
        }
      }
      .partners-list {
        .partners-item {
          height: 272px;
          &:first-child {
            height: 560px;
            .partners-title {
              font-size: 20px;
              padding: 24px 30px;
            }
          }
          .partners-title {
            font-size: 20px;
            padding: 20px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .banner-box {
      height: 240px;
      .banner-swiper {
        .banner-img {
          height: 240px;
        }
        .show-banner-pc {
          display: none;
        }
        .show-banner-mobile {
          display: block;
        }
      }
    }
    .partner-solution {
      padding: 26px 0;
      .home-title {
        padding: 10px 0;
        .title {
          font-size: 20px;
          line-height: 27px;
        }
        .more {
          font-size: 12px;
          line-height: 16px;
        }
      }
      .solution-list {
        .solution-item {
          .img-box {
            height: 180px;
          }
          .text-box {
            padding: 10px;
            .solution-name {
              font-size: 16px;
              margin-bottom: 10px;
            }
            .solution-label {
              margin: 0 5px 4px 0;
            }
          }
        }
      }
      .solution-list-pc {
        display: none;
      }
      .solution-list-mobile {
        display: flex;
      }
    }
    .partner-alliance {
      padding: 14px 0 20px 0;
      .alliance-list {
        flex-wrap: wrap;
        .alliance-item {
          width: 100%;
          min-height: 168px;
          margin-right: 0;
          & + .alliance-item {
            margin-top: 10px;
          }
          .alliance-item-text {
            padding: 22px 20px;
            .alliance-title {
              .title {
                font-size: 20px;
                line-height: 27px;
              }
              .iconfont {
                padding-right: 0;
              }
            }
            .alliance-desc {
              font-size: 14px;
              line-height: 19px;
              margin-top: 16px;
            }
          }
        }
      }
      .partners-list {
        .partners-item {
          width: 100%;
          height: auto;
          min-height: 240px;
          & + .partners-item {
            margin-left: 0;
            margin-top: 20px;
          }
          &:first-child {
            height: auto;
            min-height: 240px;
            .partners-title {
              font-size: 20px;
              padding: 24px 20px;
            }
            .partners-item-img {
              height: 100%;
            }
          }
          .partners-title {
            padding: 24px 20px;
            position: relative;
          }
          .partners-item-img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      .home-title {
        padding: 10px 0;
        .title {
          font-size: 20px;
          line-height: 27px;
        }
      }
      .join-now {
        flex-wrap: wrap;
        padding: 24px 0 32px 0;
        .text-box {
          width: 100%;
          .join-now-title {
            font-size: 18px;
            line-height: 24px;
            text-align: center;
          }
          .join-now-desc {
            font-size: 14px;
            line-height: 19px;
            margin-top: 10px;
          }
        }
        .btn-box {
          margin: 10px auto 0 auto;
        }
      }
    }
  }
}
</style>
